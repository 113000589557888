import { jsx as _jsx } from "react/jsx-runtime";
import { CHART_ASPECT_RATIO, LINE_WIDTH_0, LINE_WIDTH_1, LINE_WIDTH_2, } from "../constants";
import { DomainContext, RangeContext } from "../contexts";
import { useContext } from "react";
const useTransformPointsToPlotArea = (data) => {
    const domain = useContext(DomainContext);
    const range = useContext(RangeContext);
    return data.map(([x, y]) => [
        ((x - domain[0]) / (domain[1] - domain[0])) * CHART_ASPECT_RATIO,
        1 - (y - range[0]) / (range[1] - range[0]),
    ]);
};
const thicknessToLineWidth = [
    LINE_WIDTH_0,
    LINE_WIDTH_1,
    LINE_WIDTH_2,
];
export default function Line({ color = "var(--color-theme)", data, thickness = 1, }) {
    const transformedPoints = useTransformPointsToPlotArea(data);
    if (transformedPoints.length < 2)
        return;
    let polylinePoints = "";
    let lineLength = 0;
    let prevXY;
    for (let i = 0; i < transformedPoints.length; i++) {
        const [x, y] = transformedPoints[i];
        if (prevXY)
            lineLength += Math.hypot(x - prevXY[0], y - prevXY[1]);
        prevXY = [x, y];
        polylinePoints += `${x},${y} `;
    }
    polylinePoints = polylinePoints.trimEnd();
    return (_jsx("polyline", { className: "line", fill: "none", points: polylinePoints, stroke: color, strokeWidth: thicknessToLineWidth[thickness], style: { "--length": lineLength } }));
}
