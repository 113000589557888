import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Button, Paper, TextField } from "../..";
import { validateEmailField, validatePasswordField, } from "../../utils/validators";
import { Link } from "react-router-dom";
export default function SignUpPage({ onSubmit, ...rest }) {
    const [emailError, setEmailError] = React.useState("");
    const [passwordError, setPasswordError] = React.useState("");
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [submitError, setSubmitError] = React.useState();
    return (_jsx(Paper.Group, { ...rest, children: _jsxs(Paper, { children: [_jsx("h2", { children: "Sign up" }), _jsxs("form", { noValidate: true, onSubmit: async (e) => {
                        e.preventDefault();
                        setSubmitError(undefined);
                        const email = e.target.email.value;
                        const password = e.target.password.value;
                        const emailErrorMessage = validateEmailField(email);
                        const passwordErrorMessage = validatePasswordField(password);
                        if (emailErrorMessage || passwordErrorMessage) {
                            setEmailError(emailErrorMessage || "");
                            setPasswordError(passwordErrorMessage || "");
                            return;
                        }
                        if (emailError)
                            setEmailError("");
                        if (passwordError)
                            setPasswordError("");
                        setIsSubmitting(true);
                        try {
                            await onSubmit({ email, password, setSubmitError });
                        }
                        finally {
                            setIsSubmitting(false);
                        }
                    }, children: [_jsx(TextField, { autoComplete: "email", autoFocus: true, error: emailError, label: "Email", name: "email", type: "email" }), _jsx(TextField, { autoComplete: "new-password", error: passwordError, label: "Password", name: "password", type: "password" }), submitError && (_jsx("p", { className: "center", children: _jsx("small", { className: "negative", children: submitError }) })), _jsx(Button.Group, { children: _jsx(Button, { disabled: isSubmitting, children: "Sign up" }) }), _jsx("p", { className: "center", children: _jsxs("small", { children: ["Already have an account? ", _jsx(Link, { to: "/sign-in", children: "Sign in" }), "!"] }) })] })] }) }));
}
