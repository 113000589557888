import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Button, Paper, TextField } from "../..";
import { Link } from "react-router-dom";
import { validateEmailField } from "../../utils/validators";
export default function ResendVerificationPage({ onSubmit, ...rest }) {
    const [emailError, setEmailError] = React.useState("");
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [submitError, setSubmitError] = React.useState();
    return (_jsx(Paper.Group, { ...rest, children: _jsxs(Paper, { children: [_jsx("h2", { children: "Resend verification email" }), _jsxs("form", { noValidate: true, onSubmit: async (e) => {
                        e.preventDefault();
                        setSubmitError(undefined);
                        const email = e.target.email.value;
                        const emailErrorMessage = validateEmailField(email);
                        if (emailErrorMessage)
                            return setEmailError(emailErrorMessage);
                        if (emailError)
                            setEmailError("");
                        setIsSubmitting(true);
                        try {
                            await onSubmit({ email, setSubmitError });
                        }
                        finally {
                            setIsSubmitting(false);
                        }
                    }, children: [_jsx(TextField, { autoComplete: "email", autoFocus: true, error: emailError, label: "Email", name: "email", supportiveText: "Take care to type your address correctly", type: "email" }), submitError && (_jsx("p", { className: "center", children: _jsx("small", { className: "negative", children: submitError }) })), _jsx(Button.Group, { children: _jsx(Button, { disabled: isSubmitting, children: "Resend" }) }), _jsx("p", { className: "center", children: _jsxs("small", { children: ["Looking for the ", _jsx(Link, { to: "/sign-in", children: "sign in" }), " or", " ", _jsx(Link, { to: "/sign-up", children: "sign up" }), " pages?"] }) })] })] }) }));
}
